import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MarquesList from "../components/MarquesList"

const ToutesMarquesPage = props => {
  const { data } = props

  const { edges: marques } = data.allTaxonomyTermMarques

  return (
    <Layout>
      <SEO
        title="Marques des voitures neuves"
        description="Marque des voitures neuves commercialisé en france, Prix et
      fiche techniques et toute l'actualite des voitures neuves au maroc"
      />
      <div className="section section-md section-image overlay-dark text-white overflow-hidden background-marque"></div>

      <section className="section section-lg mb-1 p-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-1">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li className="breadcrumb-item">
                      <Link to={`/`}>Accueil</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Marques des voitures
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <section className="section pb-0 mb-0 section-md pt-5 bg-soft">
          <h1 className="text-center display-2">Marques des voitures neuves</h1>
        </section>
        <MarquesList marques={marques} showmore={false} />
      </section>
    </Layout>
  )
}

export default ToutesMarquesPage

export const pageQuery = graphql`
  query ToutesMarquesPage {
    allTaxonomyTermMarques {
      edges {
        node {
          path {
            alias
          }
          id
          name
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeActualites(sort: { fields: created, order: DESC }) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
